<template>
  <div class="app-union-list">
    <b-row>
      <b-col cols="12">
        <good-data-table
          ref="unionList"
          :button-label="$manMudra.getters.isSuperAdmin() ? 'Add Union' : ''"
          :columns="unionColumns"
          :total-column="'response.entries'"
          :data-column="'response.data'"
          :api-endpoint="'/api/Union/ListAsync'"
          :extra-params="'showAll=true'"
          :export-columns="exportColumns"
          :export-api-endpoint="'/api/Union/ExportListAsync'"
          @add-new-info="$helpers.goTo(that, 'group-union-add')"
          @table-rendered="mapStateName"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import GoodDataTable from '@/@core/layouts/shared/GoodDataTable.vue'
import { appDeleteUnion, appGetStates, appCopyUnion } from '@/@core/services/groups'
import {
  hideLoader,
  showDangerNotification,
  showErrorNotification,
  showLoader,
  showSuccessNotification,
} from '@/@core/comp-functions/ui/app'

export default {
  components: {
    GoodDataTable,
    BRow,
    BCol,
  },
  data() {
    return {
      that: this,
      unionColumns: [
        {
          label: 'Name',
          field: 'name',
        },
        {
          label: 'No. of Users',
          field: 'membersCount',
          useRenderer: true,
          renderer: props => (props.membersCount ? props.membersCount.toLocaleString() : 0),
        },
        {
          label: 'Url Suffix',
          field: 'urlSuffix',
        },
        {
          label: 'Address',
          field: 'stateId',
          useRenderer: true,
          renderer: props => (props.address
            ? [props.address.stateName, props.address.cityOrVillage]
              .filter(x => x)
              .join(', ')
            : ''),
        },
        {
          label: 'Group Owner',
          field: 'superUserEmail',
          useHtml: true,
          excelHtml: props => [props.superUserName, props.superUserEmail, props.superUserMobile]
            .filter(x => x)
            .join(', '),
          renderer: props => `
            <p class="mb-0">Name: ${
  props.superUserName ? props.superUserName : ''
}</p>
            <p class="mb-0">Email: ${
  props.superUserEmail ? props.superUserEmail : ''
}</p>
            <p class="mb-0">Mobile: ${
  props.superUserMobile ? props.superUserMobile : ''
}</p>
          `,
        },
        {
          label: 'Status',
          field: 'isActive',
          useRenderer: true,
          renderer: props => (props.isActive ? 'Active' : 'In-active'),
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
          type: 'dropdown',
          actions: [
            {
              isIcon: true,
              iconHtml: this.$helpers.getIcons('edit'),
              text: 'Edit',
              action: props => {
                this.$helpers.goTo(this, 'group-union-edit', {
                  unionId: props.id,
                })
              },
            },
            {
              isIcon: true,
              iconHtml: this.$helpers.getIcons('delete'),
              text: 'Delete',
              action: props => {
                this.deleteUnion(props.id)
              },
            },
            {
              isIcon: true,
              iconHtml: this.$helpers.getIcons('copy'),
              text: 'Copy',
              action: props => {
                this.copyUnion(props.id)
              },
            },
          ],
        },
      ],
      states: [],
      exportColumns: [
        {
          label: 'Union Name',
          value: 'name',
        },
        {
          label: 'Url Suffix',
          value: 'UrlSuffix',
        },
        {
          label: 'GovtRegNo',
          value: 'GovtRegNo',
        },
        {
          label: 'Land line No',
          value: 'LandlineNo',
        },
        {
          label: 'ContactPerson',
          value: 'contactPerson',
        },
        {
          label: 'Fee',
          value: 'Fee',
        },
        {
          label: 'FeeOf',
          value: 'FeeOf',
        },
        {
          label: 'FeeInterval',
          value: 'FeeInterval',
        },
        {
          label: 'AddressId',
          value: 'AddressId',
        },
        {
          label: 'Show Address Fields',
          value: 'ShowAddressFields',
        },
        {
          label: 'Show Work Fields',
          value: 'ShowWorkFields',
        },
        {
          label: 'Show Social Fields',
          value: 'ShowSocialFields',
        },
        {
          label: 'Show Economic Fields',
          value: 'ShowEconomicFields',
        },
        {
          label: 'Show Health EduFields',
          value: 'ShowHealthEduFields',
        },
        {
          label: 'Show Family Fields',
          value: 'ShowFamilyFields',
        },
        {
          label: 'Show Member ship',
          value: 'ShowMembership',
        },
        {
          label: 'UnionLogo',
          value: 'UnionLogo',
        },
        {
          label: 'Language',
          value: 'Language',
        },
        {
          label: 'CountryId',
          value: 'CountryId',
        },
        {
          label: 'Custom1',
          value: 'Custom1',
        },
        {
          label: 'Custom2',
          value: 'Custom2',
        },
        {
          label: 'Custom3',
          value: 'Custom3',
        },
        {
          label: 'InternationalMember',
          value: 'InternationalMember',
        },
        {
          label: 'AccountingYearStartsOn',
          value: 'AccountingYearStartsOn',
        },
        {
          label: 'ReceiptPrefix',
          value: 'ReceiptPrefix',
        },
        {
          label: 'TotalMembers',
          value: 'TotalMembers',
        },
        {
          label: 'SuperUserName',
          value: 'SuperUserName',
        },
        {
          label: 'SuperUserMobile',
          value: 'SuperUserMobile',
        },
        {
          label: 'SuperUserEmail',
          value: 'SuperUserEmail',
        },
        {
          label: 'MainUserName',
          value: 'MainUserName',
        },
        {
          label: 'MainUserMobile',
          value: 'MainUserMobile',
        },
        {
          label: 'MainUserEmail',
          value: 'MainUserEmail',
        },
        {
          label: 'State Name',
          value: 'address.stateName',
        },
        {
          label: 'District Name',
          value: 'address.districtName',
        },
        {
          label: 'SubDistrict Name',
          value: 'address.subDistrictName',
        },
        {
          label: 'Block Name',
          value: 'address.blockName',
        },
        {
          label: 'Panchayat Name',
          value: 'address.panchayatName',
        },
        {
          label: 'CityOrVillage',
          value: 'address.cityOrVillage',
        },
        {
          label: 'PinCode',
          value: 'address.pinCode',
        },
        {
          label: 'Email',
          value: 'address.email',
        },
      ],
    }
  },
  mounted() {
    this.getStates()
  },
  methods: {
    mapStateName() {
      if (this.$refs.unionList && Array.isArray(this.$refs.unionList.rows)) {
        this.$refs.unionList.rows = this.$refs.unionList.rows.map(x => {
          if (x.address && x.address.stateId) {
            const state = this.states.find(y => y.id === x.address.stateId)
            if (state) {
              // eslint-disable-next-line no-param-reassign
              x.address.stateName = state.name
              console.log(x.address.stateName)
            }
          }
          return x
        })
      }
    },
    getStates() {
      appGetStates().then(({ data }) => {
        if (data.succeeded) {
          this.states = data.data
          this.mapStateName()
        } else {
          showDangerNotification(this, data.message)
        }
      })
    },
    deleteUnion(union) {
      // eslint-disable-next-line no-alert
      const secretKey = prompt('Enter secret key')
      if (secretKey !== 'Groups_Discard') {
        showErrorNotification(this, 'Secret key not matched')
        return
      }
      // eslint-disable-next-line no-alert
      if (window.confirm('Do you really want to delete?')) {
        showLoader()
        appDeleteUnion({ id: union }).then(({ data }) => {
          if (data.succeeded) {
            showSuccessNotification(this, data.message)
            this.$refs.unionList.loadItems()
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        }).catch(() => {
          hideLoader()
        })
      }
    },
    copyUnion(union) {
      // eslint-disable-next-line no-alert
      if (window.confirm('Do you want to copy this union?')) {
        appCopyUnion({ unionId: union }).then(({ data }) => {
          if (data.succeeded) {
            showSuccessNotification(this, data.message)
            this.$refs.unionList.loadItems()
          } else {
            showDangerNotification(this, data.message)
          }
        })
      }
    },
  },
}
</script>
